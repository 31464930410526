import "./src/assets/scss/global.scss";
import React from "react";
import locationController from "./src/controllers/common/locationController";
import extraLocalStyleController from "./src/controllers/common/extraLocalStyleController";
import updaterRedirectController from "./src/controllers/services/updaterRedirectController";
import MediaAuthProvider from "./src/store/providers/MediaAuthProvider";

export const onClientEntry = () => {
  locationController();
  process.env.GATSBY_UPDATER_ENV && updaterRedirectController();
};

export const onInitialClientRender = () => {
  extraLocalStyleController();
};
export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  const { pathname, hash } = routerProps.location;
  if (pathname === "/" && hash === "#partners") {
    setTimeout(() => {
      const partners = document.querySelector("#partners");
      partners?.getBoundingClientRect().top !== 0 && partners?.scrollIntoView({
        block: "start",
        behavior: "smooth"
      });
    }, 200);
  }
  return true;
};

export const wrapRootElement = ({ element }) => {
  return (
    <MediaAuthProvider>{element}</MediaAuthProvider>
  );
};
